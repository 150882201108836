import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Refresh from '@mui/icons-material/Refresh';
import Cancel from '@mui/icons-material/Cancel';
import CreateNewFolderOutlined from '@mui/icons-material/CreateNewFolderOutlined';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Config from '../../../config.json';

var previousId = 0;
const Modes = Object.freeze({
  List: "list",
  Add:  "add",
  Edit: "edit"
});

function Companies({onLogoutClick}) {
  const [mode, setMode] = useState(Modes.List);

  const [name, setName] = useState ("");
  const [address, setAddress] = useState ("");
  const [city, setCity] = useState ("");
  const [zip, setZip] = useState ("");
  const [state, setState] = useState ("");
  const [country, setCountry] = useState ("");
  const [email, setEmail] = useState ("");
  const [work_phone, setWork_phone] = useState ("");
  const [work_fax, setWork_fax] = useState ("");
  const [website, setWebsite] = useState ("");
  const [linkedin, setLinkedin] = useState ("");
  const [notes, setNotes] = useState ("");
  
  const [companies, setCompanies] = useState([]);
  const [selCompany, setSelCompanies] = useState();

  useEffect(() => {
    fetchCompanies();
  }, []);
  
  useEffect(() => {
    if(selCompany === undefined || selCompany === null) {
      return;
    }

    
  setName(selCompany.name);
  setAddress(selCompany.address);
  setCity(selCompany.city);
  setZip(selCompany.zip);
  setState(selCompany.state);
  setCountry(selCompany.country);
  setEmail(selCompany.email);
  setWork_phone(selCompany.work_phone);
  setWork_fax(selCompany.work_fax);
  setWebsite(selCompany.website);
  setLinkedin(selCompany.linkedin);
  setNotes(selCompany.notes);
  
  }, [selCompany]);

   
  const onNameChange = (e) => { setName(e.target.value) }
  const onAddressChange = (e) => { setAddress(e.target.value) }
  const onZipChange = (e) => { setZip(e.target.value) }
  const onCityChange = (e) => { setCity(e.target.value) }
  const onStateChange = (e) => { setState(e.target.value) }
  const onCountryChange = (e) => { setCountry(e.target.value) }
  const onEmailChange = (e) => { setEmail(e.target.value) }
  const onWork_PhoneChange = (e) => { setWork_phone(e.target.value) }
  const onWork_FaxChange = (e) => { setWork_fax(e.target.value) }
  const onWebsiteChange = (e) => { setWebsite(e.target.value) }
  const onLinkedinChange = (e) => { setLinkedin(e.target.value) }
  const onNotesChange = (e) => { setNotes(e.target.value) }

  Axios.defaults.withCredentials = true;

  
  const addCompanyToDatabase = () => {
    Axios.post(Config.URL + "/addCompany", {
      name: name,
      address: address,
      city: city,
      zip: zip,
      state: state,
      country: country,
      email: email,
      work_phone: work_phone,
      work_fax: work_fax,
      website: website,
      linkedin: linkedin,
      notes: notes
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        var index = companies.length;
        fetchCompaniesWithIndexForselCompany(index);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }

  const updateCompany = () => {
    var id = selCompany.id;
    var indx = -1;
    for(var i = 0; i < companies.length; i++) {
      if(companies[i].id === id) {
        indx = i;
      } 
    }

    Axios.post(Config.URL + "/updateCompany", {
      name: name,
      address: address,
      city: city,
      zip: zip,
      state: state,
      country: country,
      email: email,
      work_phone: work_phone,
      work_fax: work_fax,
      website: website,
      linkedin: linkedin,
      notes: notes,
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true ) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        fetchCompaniesWithIndexForselCompany(indx);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }
    
  const fetchCompaniesWithIndexForselCompany = (index) => { 
    Axios.get(Config.URL + "/getAllCompanies", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setCompanies(response.data.companies);
        if(response.data.companies.length > 0) {
          setSelCompanies(response.data.companies[index]);
        } else {
          var p = {
            name: "",
            address: "",
            city: "",
            zip: "",
            state: "",
            country: "",
            email: "",
            work_phone: "",
            work_fax: "",
            website: "",
            linkedin: "",
            notes: "",
           
            id : 0        
          };
          setSelCompanies(p);
        }
      } else {
       onLogoutClick();
      }
      console.log(response);
    });
  }
 

  const fetchCompanies = () => { 
    Axios.get(Config.URL + "/getAllCompanies", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setCompanies(response.data.companies);
        if(response.data.companies.length > 0) {
          console.log("p0 = " + response.data.companies[0]);
          setSelCompanies(response.data.companies[0]);
        } else {
          var p = {
            name: "",
            address: "",
            city: "",
            zip: "",
            state: "",
            country: "",
            email: "",
            work_phone: "",
            work_fax: "",
            website: "",
            linkedin: "",
            notes: "",
            id : 0        
          };
          setSelCompanies(p);
        }
      } else {
        onLogoutClick();        
      }
      console.log(response);
    });
  }
 
  const setSelectedCompany = (company) => { 
    setSelCompanies(company);
  }
   
  const getCompanyList = () => {
    if(companies.length === 0) {
      return <>No company found in database</>
    } 

    const getListText = (company, index) => {
      if(index === selCompany.id - 1) {
        return <Typography variant="h6" disableGutters style={{fontWeight:'bolder', textAlign:'left'}}>{index + 1}. {company.name}</Typography>
      }

  return <Typography variant="h6" disableGutters style={{textAlign:'left'}}>{index + 1}. {company.name}</Typography>
}

return <List sx={{ width: '100%', maxWidth: 360, maxRows:10, bgcolor: 'background.paper' }}>
  {companies.map((company, index) => (
    <ListItem 
      key={index}
      disableGutters
    >
      <ListItemText>
      <Button  sx={{
          ':hover': {
            bgcolor: 'primary.main', // theme.palette.primary.main
            color: 'white',
          },
        }}
      onClick={(e) => {e.preventDefault(); setSelectedCompany(company);}}
      fullWidth={true}
      style={{justifyContent: "flex-start"}}>
      {getListText(company, index)}
    </Button>
    </ListItemText>
    </ListItem>
  ))}
</List>

  }

const onNewClick = () => {
  previousId = selCompany.id;

  var p = {
    name: "",
    address: "",
    city: "",
    zip: "",
    state: "",
    country: "",
    email: "",
    work_phone: "",
    work_fax: "",
    website: "",
    linkedin: "",
    notes: "",
    id : 0        
  };
  setMode(Modes.Add);
  setSelCompanies(p);
}

const onEditClick = () => {
  setMode(Modes.Edit);
}

const onDeleteClick = () => {
  if(selCompany.id < 1) {
    alert("Please select a company to delete");
    return;
  }
  
  var id = selCompany.id;
  if (window.confirm("Are you sure you want to delete the selected company? id = " + id) === true) {

    var index = companies.findIndex(x => x.id === id);

    if(index === companies.length - 1) {
      // last entry
      index = companies.length - 2;
    }   
    
    Axios.post(Config.URL + "/deleteCompany", {
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        if(index >= 0) {
          fetchCompaniesWithIndexForselCompany(index)
          setMode(Modes.List);
        } else {
          fetchCompanies();        
        }
      } else {
        onLogoutClick();
      }
    });
  }

}

const onCancelClick = () => {
  setMode(Modes.List);
  console.log("previousId = " + previousId);
  var index = -1;
  for(var i = 0; i < companies.length; i++) {
    if(companies[i].id === previousId) {
      index = i;
    } 
  }

  if(index >= 0) {
    setSelectedCompany(companies[index]);
  }
}

const getButtons = () => { 
  if(mode === Modes.Add) {
    return <>
    <Button variant="contained" startIcon={<Add />} onClick={addCompanyToDatabase} >Add To Database</Button>
    &nbsp;&nbsp;
    <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
    </>
  } else if (mode === Modes.Edit) {
    return <>
     <Button variant="contained" startIcon={<Check />} onClick={updateCompany} >Update</Button>
     &nbsp;&nbsp;
     <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
     </>
  } else {
    return <>
      <Button variant="contained" startIcon={<CreateNewFolderOutlined />} onClick={onNewClick}>New</Button>
      {companies.length > 0 &&
        selCompany.id > 0 &&
        <>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<Edit />} onClick={onEditClick}>Edit</Button>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={onDeleteClick} >Delete</Button>
        </>
      }
    </>
  }
}

  return <>
  <div>
    <br />
    <Divider textAlign="left">
      <>
          <Typography variant="h4" gutterBottom style={{textAlign:'left'}}>
            COMPANIES
            <Tooltip title="Refresh" arrow>
            <IconButton aria-label="refresh" onClick={fetchCompanies}>
              <Refresh />
            </IconButton>
            </Tooltip>
            </Typography> 
          
      </>
        </Divider>
     <br />
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {getCompanyList()}
      </Grid>
      <Grid item xs="12" md={8}>
        <div>
          <div>
            <TextField label="Name" value={name} onChange={onNameChange} fullWidth={true} /><br />
            <TextField label="Address" value={address} onChange={onAddressChange} fullWidth={true} /><br />
            <TextField label="Zip" value={zip} onChange={onZipChange} fullWidth={true} /><br />
            <TextField label="City" value={city} onChange={onCityChange} fullWidth={true} /><br />
            <TextField label="Country" value={country} onChange={onCountryChange} fullWidth={true} /><br />
            <TextField label="Email" value={email} onChange={onEmailChange} fullWidth={true} /><br />
            <TextField label="Work Phone" value={work_phone} onChange={onWork_PhoneChange} fullWidth={true} /><br />
            <TextField label="Work Fax" value={work_fax} onChange={onWork_FaxChange} fullWidth={true} /><br />
            <TextField label="Website" value={website} onChange={onWebsiteChange} fullWidth={true} /><br />
            <TextField label="LinkedIn" value={linkedin} onChange={onLinkedinChange} fullWidth={true} /><br />
            <TextField label="Notes" value={notes} onChange={onNotesChange} multiline fullWidth={true} maxRows={4} /><br />

            {getButtons()}

          </div>
        </div>
      </Grid>
    </Grid>

  </div>
  </>
}

export default Companies;
