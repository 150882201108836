import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Refresh from '@mui/icons-material/Refresh';
import Cancel from '@mui/icons-material/Cancel';
import CreateNewFolderOutlined from '@mui/icons-material/CreateNewFolderOutlined';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Config from '../../../config.json';

var previousId = 0;
const Modes = Object.freeze({
  List: "list",
  Add:  "add",
  Edit: "edit"
});

function Contacts({onLogoutClick}) {
  const [mode, setMode] = useState(Modes.List);

  const [name, setName] = useState ("");
  const [title, setTitle] = useState ("");
  const [company, setCompany] = useState ("");
  const [email, setEmail] = useState ("");
  const [work_phone, setWork_phone] = useState ("");
  const [work_fax, setWork_fax] = useState ("");
  const [website, setWebsite] = useState ("");
  const [address, setAddress] = useState ("");
  const [zip, setZip] = useState ("");
  const [city, setCity] = useState ("");
  const [state, setState] = useState ("");
  const [country, setCountry] = useState ("");
  const [linkedin, setLinkedin] = useState ("");
  const [notes, setNotes] = useState ("");

  const [contacts, setContacts] = useState([]);
  const [selContact, setSelContact] = useState();
  
  useEffect(() => {
    fetchContacts();
  }, []);
  
  useEffect(() => {
    if(selContact === undefined || selContact === null) {
      return;
    }


 
    setName(selContact.name);
    setTitle(selContact.title);
    setCompany(selContact.company);
    setEmail(selContact.email);
    setWork_phone(selContact.work_phone);
    setWork_fax(selContact.work_fax);
    setWebsite(selContact.website);
    setAddress(selContact.address);
    setZip(selContact.zip);
    setCity(selContact.city);
    setState(selContact.stage);
    setCountry(selContact.country);
    setLinkedin(selContact.linkedin);
    setNotes(selContact.notes);
  
  }, [selContact]);


 
  const onNameChange = (e) => { setName(e.target.value) }
  const onTitleChange = (e) => { setTitle(e.target.value) }
  const onCompanyChange = (e) => { setCompany(e.target.value) }
  const onEmailChange = (e) => { setEmail(e.target.value) }
  const onWork_phoneChange = (e) => { setWork_phone(e.target.value) }
  const onWork_faxChange = (e) => { setWork_fax(e.target.value) }
  const onWebsiteChange = (e) => { setWebsite(e.target.value) }
  const onAddressChange = (e) => { setAddress(e.target.value) }
  const onZipChange = (e) => { setZip(e.target.value) }
  const onCityChange = (e) => { setCity(e.target.value) }
  const onStateChange = (e) => { setState(e.target.value) }
  const onCountryChange = (e) => { setCountry(e.target.value) }
  const onLinkedinChange = (e) => { setLinkedin(e.target.value) }
  const onNotesChange = (e) => { setNotes(e.target.value) }



  Axios.defaults.withCredentials = true;

  
  const addContactToDatabase = () => {
    
    Axios.post(Config.URL + "/addContact", {

      name: name,
      title: title,
      company: company,
      email: email,
      work_phone: work_phone,
      work_fax: work_fax,
      website: website,
      address: address,
      zip: zip,
      city: city,
      state: state,
      country: country,
      linkedin: linkedin,
      notes: notes

    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        var index = contacts.length;
        fetchContactsWithIndexForSelContact(index);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }

  const updateContact = () => {
    var id = selContact.id;
    var indx = -1;
    for(var i = 0; i < contacts.length; i++) {
      if(contacts[i].id === id) {
        indx = i;
      } 
    }

    Axios.post(Config.URL + "/updateContact", {

      name: name,
      title: title,
      company: company,
      email: email,
      work_phone: work_phone,
      work_fax: work_fax,
      website: website,
      address: address,
      zip: zip,
      city: city,
      state: state,
      country: country,
      linkedin: linkedin,
      notes: notes,
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true ) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        fetchContactsWithIndexForSelContact(indx);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }
    
  const fetchContactsWithIndexForSelContact = (index) => { 
    Axios.get(Config.URL + "/getAllContacts", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setContacts(response.data.contacts);
        if(response.data.contacts.length > 0) {
          setSelContact(response.data.contacts[index]);
        } else {
          var p = {
            name: "",
            title: "",
            company: "",
            email: "",
            work_phone: "",
            work_fax: "",
            website: "",
            address: "",
            zip: "",
            city: "",
            state: "",
            country: "",
            linkedin: "",
            notes: "",
            
            id : 0        
          };
          setSelContact(p);
        }
      } else {
       onLogoutClick();
      }
      console.log(response);
    });
  }
 

  const fetchContacts = () => { 
    Axios.get(Config.URL + "/getAllContacts", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setContacts(response.data.contacts);
        if(response.data.contacts.length > 0) {
          console.log("p0 = " + response.data.contacts[0]);
          setSelContact(response.data.contacts[0]);
        } else {
          var p = {
            name: "",
            title: "",
            company: "",
            email: "",
            work_phone: "",
            work_fax: "",
            website: "",
            address: "",
            zip: "",
            city: "",
            state: "",
            country: "",
            linkedin: "",
            notes: "",
            id : 0        
          };
          setSelContact(p);
        }
      } else {
        onLogoutClick();        
      }
      console.log(response);
    });
  }
 
  const setSelectedContact = (contact) => { 
    setSelContact(contact);
  }
   
  const getContactList = () => {
    if(contacts.length === 0) {
      return <>No contact found in database</>
    } 

    const getListText = (contact, index) => {

      var txt = `${contact.name} [${contact.title}, ${contact.company}]`;
      
      if(index === selContact.id - 1) {
        return <Typography variant="h6" disableGutters style={{fontWeight:'bolder', textAlign:'left'}}>{index + 1}. {txt}</Typography>
      }

  return <Typography variant="h6" disableGutters style={{textAlign:'left'}}>{index + 1}. {txt}</Typography>
}

return <List sx={{ width: '100%', maxWidth: 360, maxRows:10, bgcolor: 'background.paper' }}>
  {contacts.map((contact, index) => (
    <ListItem 
      key={index}
      disableGutters
    >
      <ListItemText>
      <Button  sx={{
          ':hover': {
            bgcolor: 'primary.main', // theme.palette.primary.main
            color: 'white',
          },
        }}
      onClick={(e) => {e.preventDefault(); setSelectedContact(contact);}}
      fullWidth={true}
      style={{justifyContent: "flex-start"}}>
      {getListText(contact, index)}
    </Button>
    </ListItemText>
    </ListItem>
  ))}
</List>

  }

const onNewClick = () => {
  previousId = selContact.id;

  var p = {
    name: "",
    title: "",
    company: "",
    email: "",
    work_phone: "",
    work_fax: "",
    website: "",
    address: "",
    zip: "",
    city: "",
    state: "",
    country: "",
    linkedin: "",
    notes: "",
    id : 0        
  };
  setMode(Modes.Add);
  setSelContact(p);
}

const onEditClick = () => {
  setMode(Modes.Edit);
}

const onDeleteClick = () => {
  if(selContact.id < 1) {
    alert("Please select a contact to delete");
    return;
  }
  
  var id = selContact.id;
  if (window.confirm("Are you sure you want to delete the selected contact? id = " + id) === true) {

    var index = contacts.findIndex(x => x.id === id);

    if(index === contacts.length - 1) {
      // last entry
      index = contacts.length - 2;
    }   
    
    Axios.post(Config.URL + "/deleteContact", {
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        if(index >= 0) {
          fetchContactsWithIndexForSelContact(index)
          setMode(Modes.List);
        } else {
          fetchContacts();        
        }
      } else {
        onLogoutClick();
      }
    });
  }

}

const onCancelClick = () => {
  setMode(Modes.List);
  console.log("previousId = " + previousId);
  var index = -1;
  for(var i = 0; i < contacts.length; i++) {
    if(contacts[i].id === previousId) {
      index = i;
    } 
  }

  if(index >= 0) {
    setSelectedContact(contacts[index]);
  }
}

const getButtons = () => { 
  if(mode === Modes.Add) {
    return <>
    <Button variant="contained" startIcon={<Add />} onClick={addContactToDatabase} >Add To Database</Button>
    &nbsp;&nbsp;
    <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
    </>
  } else if (mode === Modes.Edit) {
    return <>
     <Button variant="contained" startIcon={<Check />} onClick={updateContact} >Update</Button>
     &nbsp;&nbsp;
     <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
     </>
  } else {
    return <>
      <Button variant="contained" startIcon={<CreateNewFolderOutlined />} onClick={onNewClick}>New</Button>
      {contacts.length > 0 &&
        selContact.id > 0 &&
        <>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<Edit />} onClick={onEditClick}>Edit</Button>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={onDeleteClick} >Delete</Button>
        </>
      }
    </>
  }
}

  return <>
  <div>
    <br />
    <Divider textAlign="left">
      <>
          <Typography variant="h4" gutterBottom style={{textAlign:'left'}}>
            CONTACTS
            <Tooltip title="Refresh" arrow>
            <IconButton aria-label="refresh" onClick={fetchContacts}>
              <Refresh />
            </IconButton>
            </Tooltip>
            </Typography> 
          
      </>
        </Divider>
     <br />
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {getContactList()}
      </Grid>
      <Grid item xs="12" md={8}>
        <div>
          <div>

            <TextField label="Name" value={name} onChange={onNameChange} fullWidth={true} /><br />
            <TextField label="Title" value={title} onChange={onTitleChange} fullWidth={true} /><br />
            <TextField label="Company" value={company} onChange={onCompanyChange} fullWidth={true} /><br />
            <TextField label="Email" value={email} onChange={onEmailChange} fullWidth={true} /><br />
            <TextField label="Work Phone" value={work_phone} onChange={onWork_phoneChange} fullWidth={true} /><br />
            <TextField label="Work Fax" value={work_fax} onChange={onWork_faxChange} fullWidth={true} /><br />
            <TextField label="Website" value={website} onChange={onWebsiteChange} fullWidth={true} /><br />
            <TextField label="Address" value={address} onChange={onAddressChange} fullWidth={true} /><br />
            <TextField label="City" value={city} onChange={onCityChange} fullWidth={true} /><br />
            <TextField label="State" value={state} onChange={onStateChange} fullWidth={true} /><br />
            <TextField label="Zip" value={zip} onChange={onZipChange} fullWidth={true} /><br />
            <TextField label="Country" value={country} onChange={onCountryChange} fullWidth={true} /><br />
            <TextField label="LinkedIn" value={linkedin} onChange={onLinkedinChange} multiline fullWidth={true} maxRows={4} /><br />
            <TextField label="Notes" value={notes} onChange={onNotesChange} multiline fullWidth={true} maxRows={4} /><br />

            {getButtons()}

          </div>
        </div>
      </Grid>
    </Grid>

  </div>
  </>
}

export default Contacts;
