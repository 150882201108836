import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Refresh from '@mui/icons-material/Refresh';
import Cancel from '@mui/icons-material/Cancel';
import CreateNewFolderOutlined from '@mui/icons-material/CreateNewFolderOutlined';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Config from '../../../config.json';

var previousId = 0;
const Modes = Object.freeze({
  List: "list",
  Add:  "add",
  Edit: "edit"
});

function Pipelines({onLogoutClick}) {
  const [mode, setMode] = useState(Modes.List);

  const [product, setProduct] = useState ("");
  const [company, setCompany] = useState ("");
  const [contact, setContact] = useState ("");
  const [stage, setStage] = useState ("");
  const [_value, setValue] = useState ("");
  const [outcome, setOutcome] = useState ("");
  const [is_closed, setIs_closed] = useState ("");
  const [close_date, setClose_date] = useState ("");
  const [loss_reason, setLoss_reason] = useState ("");
  const [priority, setPriority] = useState ("");
  const [source, setSource] = useState ("");
  const [description, setDescription] = useState ("");
  const [notes, setNotes] = useState ("");

  const [pipelines, setPipelines] = useState([]);
  const [selPipeline, setSelPipeline] = useState();
  
  useEffect(() => {
    fetchPipelines();
  }, []);
  
  useEffect(() => {
    if(selPipeline === undefined || selPipeline === null) {
      return;
    }


    setProduct(selPipeline.product);
    setCompany(selPipeline.company);
    setContact(selPipeline.contact);
    setStage(selPipeline.stage);
    setValue(selPipeline.value);
    setOutcome(selPipeline.outcome);
    setIs_closed(selPipeline.is_closed);
    setClose_date(selPipeline.close_date);
    setLoss_reason(selPipeline.loss_reason);
    setPriority(selPipeline.priority);
    setSource(selPipeline.source);
    setDescription(selPipeline.description);
    setNotes(selPipeline.notes);

  }, [selPipeline]);



  const onProductChange = (e) => { setProduct(e.target.value) }
  const onCompanyChange = (e) => { setCompany(e.target.value) }
  const onContactChange = (e) => { setContact(e.target.value) }
  const onStageChange = (e) => { setStage(e.target.value) }
  const onValueChange = (e) => { setValue(e.target.value) }
  const onOutcomeChange = (e) => { setOutcome(e.target.value) }
  const onIs_closedChange = (e) => { setIs_closed(e.target.value) }
  const onClose_dateChange = (e) => { setClose_date(e.target.value) }
  const onLoss_reasonChange = (e) => { setLoss_reason(e.target.value) }
  const onPriorityChange = (e) => { setPriority(e.target.value) }
  const onSourceChange = (e) => { setSource(e.target.value) }
  const onDescriptionChange = (e) => { setDescription(e.target.value) }
  const onNotesChange = (e) => { setNotes(e.target.value) }



  Axios.defaults.withCredentials = true;

  
  const addPipelineToDatabase = () => {
    
    var cd = Date.now();
    if(close_date !== undefined) {
      cd = close_date;
    }
    
    Axios.post(Config.URL + "/addPipeline", {

      product: product,
      company: company,
      contact: contact,
      stage: stage,
      value: _value,
      outcome: outcome,
      is_closed: is_closed,
      close_date: cd,
      loss_reason: loss_reason,
      priority: priority,
      source: source,
      description: description,
      notes: notes

    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        var index = pipelines.length;
        fetchPipelinesWithIndexForSelPipeline(index);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }

  const updatePipeline = () => {
    var cd = Date.now();
    if(close_date !== undefined) {
      cd = close_date;
    }
    var id = selPipeline.id;
    var indx = -1;
    for(var i = 0; i < pipelines.length; i++) {
      if(pipelines[i].id === id) {
        indx = i;
      } 
    }

    Axios.post(Config.URL + "/updatePipeline", {

      product: product,
      company: company,
      contact: contact,
      stage: stage,
      value: _value,
      outcome: outcome,
      is_closed: is_closed,
      close_date: cd,
      loss_reason: loss_reason,
      priority: priority,
      source: source,
      description: description,
      notes: notes,
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true ) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        fetchPipelinesWithIndexForSelPipeline(indx);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }
    
  const fetchPipelinesWithIndexForSelPipeline = (index) => { 
    Axios.get(Config.URL + "/getAllPipelines", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setPipelines(response.data.pipelines);
        if(response.data.pipelines.length > 0) {
          setSelPipeline(response.data.pipelines[index]);
        } else {
          var p = {
            product: "",
            company: "",
            contact: "",
            stage: "",
            _value: "",
            outcome: "",
            is_closed: "",
            close_date: "",
            loss_reason: "",
            priority: "",
            source: "",
            description: "",
            notes: "",
      
            id : 0        
          };
          setSelPipeline(p);
        }
      } else {
       onLogoutClick();
      }
      console.log(response);
    });
  }
 

  const fetchPipelines = () => { 
    Axios.get(Config.URL + "/getAllPipelines", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setPipelines(response.data.pipelines);
        if(response.data.pipelines.length > 0) {
          console.log("p0 = " + response.data.pipelines[0]);
          setSelPipeline(response.data.pipelines[0]);
        } else {
          var p = {
            product: "",
            company: "",
            contact: "",
            stage: "",
            _value: "",
            outcome: "",
            is_closed: "",
            close_date: "",
            loss_reason: "",
            priority: "",
            source: "",
            description: "",
            notes: "",
            id : 0        
          };
          setSelPipeline(p);
        }
      } else {
        onLogoutClick();        
      }
      console.log(response);
    });
  }
 
  const setSelectedPipeline = (pipeline) => { 
    setSelPipeline(pipeline);
  }
   
  const getPipelineList = () => {
    if(pipelines.length === 0) {
      return <>No pipeline found in database</>
    } 

    const getListText = (pipeline, index) => {

      var txt = `${pipeline.product}, ${pipeline.company} [${pipeline.priority}]`;
      
      if(index === selPipeline.id - 1) {
        return <Typography variant="h6" disableGutters style={{fontWeight:'bolder', textAlign:'left'}}>{index + 1}. {txt}</Typography>
      }

  return <Typography variant="h6" disableGutters style={{textAlign:'left'}}>{index + 1}. {txt}</Typography>
}

return <List sx={{ width: '100%', maxWidth: 360, maxRows:10, bgcolor: 'background.paper' }}>
  {pipelines.map((pipeline, index) => (
    <ListItem 
      key={index}
      disableGutters
    >
      <ListItemText>
      <Button  sx={{
          ':hover': {
            bgcolor: 'primary.main', // theme.palette.primary.main
            color: 'white',
          },
        }}
      onClick={(e) => {e.preventDefault(); setSelectedPipeline(pipeline);}}
      fullWidth={true}
      style={{justifyContent: "flex-start"}}>
      {getListText(pipeline, index)}
    </Button>
    </ListItemText>
    </ListItem>
  ))}
</List>

  }

const onNewClick = () => {
  previousId = selPipeline.id;

  var p = {

    product: "",
    company: "",
    contact: "",
    stage: "",
    _value: "",
    outcome: "",
    is_closed: "",
    close_date: "",
    loss_reason: "",
    priority: "",
    source: "",
    description: "",
    notes: "",
    id : 0        
  };
  setMode(Modes.Add);
  setSelPipeline(p);
}

const onEditClick = () => {
  setMode(Modes.Edit);
}

const onDeleteClick = () => {
  if(selPipeline.id < 1) {
    alert("Please select a pipeline to delete");
    return;
  }
  
  var id = selPipeline.id;
  if (window.confirm("Are you sure you want to delete the selected pipeline? id = " + id) === true) {

    var index = pipelines.findIndex(x => x.id === id);

    if(index === pipelines.length - 1) {
      // last entry
      index = pipelines.length - 2;
    }   
    
    Axios.post(Config.URL + "/deletePipeline", {
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        if(index >= 0) {
          fetchPipelinesWithIndexForSelPipeline(index)
          setMode(Modes.List);
        } else {
          fetchPipelines();        
        }
      } else {
        onLogoutClick();
      }
    });
  }

}

const onCancelClick = () => {
  setMode(Modes.List);
  console.log("previousId = " + previousId);
  var index = -1;
  for(var i = 0; i < pipelines.length; i++) {
    if(pipelines[i].id === previousId) {
      index = i;
    } 
  }

  if(index >= 0) {
    setSelectedPipeline(pipelines[index]);
  }
}

const getButtons = () => { 
  if(mode === Modes.Add) {
    return <>
    <Button variant="contained" startIcon={<Add />} onClick={addPipelineToDatabase} >Add To Database</Button>
    &nbsp;&nbsp;
    <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
    </>
  } else if (mode === Modes.Edit) {
    return <>
     <Button variant="contained" startIcon={<Check />} onClick={updatePipeline} >Update</Button>
     &nbsp;&nbsp;
     <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
     </>
  } else {
    return <>
      <Button variant="contained" startIcon={<CreateNewFolderOutlined />} onClick={onNewClick}>New</Button>
      {pipelines.length > 0 &&
        selPipeline.id > 0 &&
        <>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<Edit />} onClick={onEditClick}>Edit</Button>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={onDeleteClick} >Delete</Button>
        </>
      }
    </>
  }
}

  return <>
  <div>
    <br />
    <Divider textAlign="left">
      <>
          <Typography variant="h4" gutterBottom style={{textAlign:'left'}}>
            PIPELINES
            <Tooltip title="Refresh" arrow>
            <IconButton aria-label="refresh" onClick={fetchPipelines}>
              <Refresh />
            </IconButton>
            </Tooltip>
            </Typography> 
          
      </>
        </Divider>
     <br />
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {getPipelineList()}
      </Grid>
      <Grid item xs="12" md={8}>
        <div>
          <div>

            <TextField label="Product" value={product} onChange={onProductChange} fullWidth={true} /><br />
            <TextField label="Company" value={company} onChange={onCompanyChange} fullWidth={true} /><br />
            <TextField label="Contact" value={contact} onChange={onContactChange} fullWidth={true} /><br />
            <TextField label="Stage" value={stage} onChange={onStageChange} fullWidth={true} /><br />
            <TextField label="Value" value={_value} onChange={onValueChange} fullWidth={true} /><br />
            <TextField label="Closed" value={is_closed} onChange={onIs_closedChange} fullWidth={true} /><br />
            <TextField label="Closing Date" value={close_date} onChange={onClose_dateChange} fullWidth={true} /><br />
            <TextField label="Outcome" value={outcome} onChange={onOutcomeChange} fullWidth={true} /><br />
            <TextField label="Loss Reason" value={loss_reason} onChange={onLoss_reasonChange} fullWidth={true} /><br />
            <TextField label="Priority" value={priority} onChange={onPriorityChange} fullWidth={true} /><br />
            <TextField label="Source" value={source} onChange={onSourceChange} fullWidth={true} /><br />
            <TextField label="Description" value={description} onChange={onDescriptionChange} multiline fullWidth={true} maxRows={4} /><br />
            <TextField label="Notes" value={notes} onChange={onNotesChange} multiline fullWidth={true} maxRows={4} /><br />

            {getButtons()}

          </div>
        </div>
      </Grid>
    </Grid>

  </div>
  </>
}

export default Pipelines;
