import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Pointers from './components/Pointers';
import Pipelines from './components/Pipelines';
import Contacts from './components/Contacts';
import Companies from './components/Companies';


import { useNavigate } from 'react-router-dom'; 

function Main({loginStatus, onLogoutClick, selectedTab}) {
  console.log("Entered main... selectedTab = "  + selectedTab);
  const navigate = useNavigate(); 

  console.log('in main, loginStatus = ' + loginStatus);

  useEffect(() => {
    if(!loginStatus) {
      console.log("Navigating back to / becausae of !loginStatus in []");
      navigate('/', { replace: true });
    }
  }, []);
  
  useEffect(() => {
    if(!loginStatus) {
      console.log("Navigating back to / becausae of !loginStatus in [loginStatus]");
      navigate('/', { replace: true });
    }
  }, [loginStatus]);

  console.log("reaced apst the two useEffects...");

  const getContents = () => {
    if(selectedTab === "pointers") {
      return <Pointers onLogoutClick={onLogoutClick} />
    } else if(selectedTab === "pipelines") {
      return <Pipelines onLogoutClick={onLogoutClick} />
    } else if(selectedTab === "contacts") {
      return <Contacts onLogoutClick={onLogoutClick} />
    } else if(selectedTab === "companies") {
      return <Companies onLogoutClick={onLogoutClick} />
    }
  }
  
  return <> { getContents() } </>
}

export default Main;
