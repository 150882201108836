import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import { TextField } from "@mui/material";
import Axios from 'axios';
import Typography from '@mui/material/Typography';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Divider } from '@mui/material';
import { Grid } from '@mui/material';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { ListItemText } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import Add from '@mui/icons-material/Add';
import Refresh from '@mui/icons-material/Refresh';
import Cancel from '@mui/icons-material/Cancel';
import CreateNewFolderOutlined from '@mui/icons-material/CreateNewFolderOutlined';
import Edit from '@mui/icons-material/Edit';
import Check from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Config from '../../../config.json';

var previousId = 0;
const Modes = Object.freeze({
  List: "list",
  Add:  "add",
  Edit: "edit"
});

function Pointers({onLogoutClick}) {
  const [mode, setMode] = useState(Modes.List);
  const [title, setTitle] = useState ("");
  const [product, setProduct] = useState ("");
  const [company, setCompany] = useState ("");
  const [_value, setValue] = useState ("");
  const [priority, setPriority] = useState ("");
  const [source, setSource] = useState ("");
  const [notes, setNotes] = useState ("");
  const [pointers, setPointers] = useState([]);
  const [selPointer, setSelPointer] = useState();
  
  // function isTokenExpired(token) {
  //   if(token === null) return true;
  //   const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
  //   return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
  // }
  
  // var token = localStorage.getItem("token");
  // if(isTokenExpired(token)) {
  //   onLogoutClick();
  // }

  useEffect(() => {
    fetchPointers();
  }, []);
  
  useEffect(() => {
    if(selPointer === undefined || selPointer === null) {
      return;
    }
    setTitle(selPointer.title);
    setProduct(selPointer.product);
    setCompany(selPointer.company);
    setValue(selPointer.value);
    setPriority(selPointer.priority);
    setSource(selPointer.source);
    setNotes(selPointer.notes);
  }, [selPointer]);

   
  const onTitleChange = (e) => { setTitle(e.target.value) }
  const onProductChange = (e) => { setProduct(e.target.value) }
  const onCompanyChange = (e) => { setCompany(e.target.value) }
  const onValueChange = (e) => { setValue(e.target.value) }
  const onPriorityChange = (e) => { setPriority(e.target.value) }
  const onSourceChange = (e) => { setSource(e.target.value) }
  const onNotesChange = (e) => { setNotes(e.target.value) }

  Axios.defaults.withCredentials = true;

  
  const addPointerToDatabase = () => {
    Axios.post(Config.URL + "/addPointer", {
      title: title,
      product: product,
      company: company,
      value: _value,
      priority: priority,
      source: source,
      notes: notes
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        var index = pointers.length;
        fetchPointersWithIndexForSelPointer(index);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }

  const updatePointer = () => {
    var id = selPointer.id;
    var indx = -1;
    for(var i = 0; i < pointers.length; i++) {
      if(pointers[i].id === id) {
        indx = i;
      } 
    }

    Axios.post(Config.URL + "/updatePointer", {
      title: title,
      product: product,
      company: company,
      value: _value,
      priority: priority,
      source: source,
      notes: notes,
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true ) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        fetchPointersWithIndexForSelPointer(indx);
        setMode(Modes.List);
      } else {
        onLogoutClick();
      }
    });
  }
    
  const fetchPointersWithIndexForSelPointer = (index) => { 
    Axios.get(Config.URL + "/getAllPointers", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setPointers(response.data.pointers);
        if(response.data.pointers.length > 0) {
          setSelPointer(response.data.pointers[index]);
        } else {
          var p = {
            title : "",
            product : "",
            company : "",
            value : "",
            priority : "",
            source : "",
            notes : "",
            id : 0        
          };
          setSelPointer(p);
        }
      } else {
       onLogoutClick();
      }
      console.log(response);
    });
  }
 

  const fetchPointers = () => { 
    Axios.get(Config.URL + "/getAllPointers", 
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        setPointers(response.data.pointers);
        if(response.data.pointers.length > 0) {
          console.log("p0 = " + response.data.pointers[0]);
          setSelPointer(response.data.pointers[0]);
        } else {
          var p = {
            title : "",
            product : "",
            company : "",
            value : "",
            priority : "",
            source : "",
            notes : "",
            id : 0        
          };
          setSelPointer(p);
        }
      } else {
        console.log("INvoking logout from getAllPointers");
        console.log(JSON.stringify(response));
        onLogoutClick();        
      }
      console.log(response);
    });
  }
 
  const setSelectedPointer = (pointer) => { 
    setSelPointer(pointer);
  }
   
  const getPointerList = () => {
    if(pointers.length === 0) {
      return <>No pointer found in database</>
    } 

    const getListText = (pointer, index) => {
      if(index === selPointer.id - 1) {
        return <Typography variant="h6" disableGutters style={{fontWeight:'bolder', textAlign:'left'}}>{index + 1}. {pointer.title}</Typography>
      }

  return <Typography variant="h6" disableGutters style={{textAlign:'left'}}>{index + 1}. {pointer.title}</Typography>
}

return <List sx={{ width: '100%', maxWidth: 360, maxRows:10, bgcolor: 'background.paper' }}>
  {pointers.map((pointer, index) => (
    <ListItem 
      key={index}
      disableGutters
    >
      <ListItemText>
      <Button  sx={{
          ':hover': {
            bgcolor: 'primary.main', // theme.palette.primary.main
            color: 'white',
          },
        }}
      onClick={(e) => {e.preventDefault(); setSelectedPointer(pointer);}}
      fullWidth={true}
      style={{justifyContent: "flex-start"}}>
      {getListText(pointer, index)}
    </Button>
    </ListItemText>
    </ListItem>
  ))}
</List>

  }

const onNewClick = () => {
  previousId = selPointer.id;

  var p = {
    title : "",
    product : "",
    company : "",
    value : "",
    priority : "",
    source : "",
    notes : "",
    id : 0        
  };
  setMode(Modes.Add);
  setSelPointer(p);
}

const onEditClick = () => {
  setMode(Modes.Edit);
}

const onDeleteClick = () => {
  if(selPointer.id < 1) {
    alert("Please select a pointer to delete");
    return;
  }
  
  var id = selPointer.id;
  if (window.confirm("Are you sure you want to delete the selected pointer? id = " + id) === true) {

    var index = pointers.findIndex(x => x.id === id);

    if(index === pointers.length - 1) {
      // last entry
      index = pointers.length - 2;
    }   
    
    Axios.post(Config.URL + "/deletePointer", {
      id: id
    },
    {headers: {
      "x-access-token": localStorage.getItem("token"),
    }}).then((response) => {
      console.log(response);
      if(response.data.success === true) {
        if(response.data.newToken !== null) {
          localStorage.setItem("token", response.data.newToken);
        }
        if(index >= 0) {
          fetchPointersWithIndexForSelPointer(index)
          setMode(Modes.List);
        } else {
          fetchPointers();        
        }
      } else {
        onLogoutClick();
      }
    });
  }

}

const onCancelClick = () => {
  setMode(Modes.List);
  console.log("previousId = " + previousId);
  var index = -1;
  for(var i = 0; i < pointers.length; i++) {
    if(pointers[i].id === previousId) {
      index = i;
    } 
  }

  if(index >= 0) {
    setSelectedPointer(pointers[index]);
  }
}

const getButtons = () => { 
  if(mode === Modes.Add) {
    return <>
    <Button variant="contained" startIcon={<Add />} onClick={addPointerToDatabase} >Add To Database</Button>
    &nbsp;&nbsp;
    <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
    </>
  } else if (mode === Modes.Edit) {
    return <>
     <Button variant="contained" startIcon={<Check />} onClick={updatePointer} >Update</Button>
     &nbsp;&nbsp;
     <Button variant="contained" startIcon={<Cancel />} onClick={onCancelClick} >Cancel</Button>
     </>
  } else {
    return <>
      <Button variant="contained" startIcon={<CreateNewFolderOutlined />} onClick={onNewClick}>New</Button>
      {pointers.length > 0 &&
        selPointer.id > 0 &&
        <>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<Edit />} onClick={onEditClick}>Edit</Button>
          &nbsp;&nbsp;
          <Button variant="contained" startIcon={<DeleteIcon />} onClick={onDeleteClick} >Delete</Button>
        </>
      }
    </>
  }
}

  return <>
  <div>
    <br />
    <Divider textAlign="left">
      <>
          <Typography variant="h4" gutterBottom style={{textAlign:'left'}}>
            POINTERS
            <Tooltip title="Refresh" arrow>
            <IconButton aria-label="refresh" onClick={fetchPointers}>
              <Refresh />
            </IconButton>
            </Tooltip>
            </Typography> 
          
      </>
        </Divider>
     <br />
    <Grid container spacing={3}>
      <Grid item xs={12} md={4}>
        {getPointerList()}
      </Grid>
      <Grid item xs="12" md={8}>
        <div>
          <div>
            <TextField label="Title" value={title} onChange={onTitleChange} fullWidth={true} /><br />
            <TextField label="Product" value={product} onChange={onProductChange} fullWidth={true} /><br />
            <TextField label="Company" value={company} onChange={onCompanyChange} fullWidth={true} /><br />
            <TextField label="Value" value={_value} onChange={onValueChange} fullWidth={true} /><br />
            <TextField label="Priority" value={priority} onChange={onPriorityChange} fullWidth={true} /><br />
            <TextField label="Source" value={source} onChange={onSourceChange} fullWidth={true} /><br />
            <TextField label="Notes" value={notes} onChange={onNotesChange} multiline fullWidth={true} maxRows={4} /><br />

            {getButtons()}

          </div>
        </div>
      </Grid>
    </Grid>

  </div>
  </>
}

export default Pointers;
